import React, { FC } from 'react'
import Pill from '../Pill'
import * as S from './styled'

type Props = {
  pills?: string[]
  activePills?: string[]
}

const PillList: FC<Props> = ({ pills = [], activePills = [] }) => {
  return (
    <S.PillListContainer>
      {pills?.map(pill => {
        const PillComponent = activePills.includes(pill.toLowerCase()) ? Pill.New : Pill

        return <PillComponent label={pill} key={pill} />
      })}
    </S.PillListContainer>
  )
}

export default PillList
