import React from 'react'

function QuotationMark() {
  return (
    <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.06398 18.82C3.28398 18.82 0.823984 15.58 0.823984 10.96C0.823984 4.18 5.20398 0.699997 11.984 0.639998V5.02C8.20398 5.02 6.34398 6.94 6.34398 9.1C6.34398 9.34 6.34398 9.64 6.40398 9.94C7.00398 9.64 7.60398 9.46 8.32399 9.46C10.484 9.46 12.344 10.96 12.344 13.96C12.344 16.66 10.064 18.82 7.06398 18.82ZM20.084 18.82C16.304 18.82 13.784 15.58 13.784 10.96C13.784 4.18 18.164 0.699997 25.004 0.639998V5.02C21.164 5.02 19.304 6.94 19.304 9.1C19.304 9.34 19.304 9.64 19.364 9.94C19.964 9.64 20.564 9.46 21.284 9.46C23.504 9.46 25.364 10.96 25.364 13.96C25.364 16.66 23.024 18.82 20.084 18.82Z"
        fill="#F82648"
      />
    </svg>
  )
}

export default QuotationMark
