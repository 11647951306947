import styled from 'styled-components'
import { breakpoints } from '../../../theme/mediaBreakpoints'
import { Body2 } from '../../../theme/typography'
import Image from '../../Image'

export const Container = styled.div`
  display: flex;
  height: 300px;
  width: 100%;
  width: min(1000px, calc(100vw - 40px));
  background-color: var(--background-variant);
  border-radius: 8px;
  overflow: hidden;

  ${breakpoints.mobile} {
    min-width: 100%;
    margin: 0 auto;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 0 auto;
  padding: 68px;
`

export const Quote = styled(Body2)`
  font-size: 1.8rem;
  line-height: 1.5;
`

export const Author = styled(Body2)`
  font-size: 1.8rem;
  font-family: 'Aeonik-Bold';
  line-height: 1;
`

export const JobTitle = styled(Body2)`
  font-size: 1.6rem;
  line-height: 1;
  opacity: 0.7;
`

export const ProfilePic = styled(Image)`
  height: 100%;
  width: 214px;
`

export const ImageWrapper = styled.div`
  position: relative;

  &,
  & > div:first-child {
    height: 100%;
  }

  img {
    object-fit: cover;
  }
`

export const QuoteBadgeWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid var(--accent);
  height: 54px;
  width: 54px;
  position: absolute;
  right: 0;
  top: 24px;
  transform: translateX(50%);
`
