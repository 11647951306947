import { graphql, useStaticQuery } from 'gatsby'
import React, { FC } from 'react'
import Reveal, { Fade } from 'react-awesome-reveal'
import { Col, Container, Row, Visible } from 'react-grid-system'
import Helmet from 'react-helmet'
import DoYouHaveAnIdea from '../components/DoYouHaveAnIdea'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Image from '../components/Image'
import SocialLinks from '../components/SocialLinks'
import animations from '../theme/animations'
import { BannerImageContainer, CenteredContent, FullWidthImage, Spacer } from '../theme/base'
import { Body1, Headline1, Title2 } from '../theme/typography'
import { Concept } from '../types'
import { parseContentfulFileUrl } from '../util/helpers'
import PillList from '../components/__general/PillList'
import Testimonial from '../components/__general/Testimonial'

type Props = {
  pageContext: {
    concept: Concept
  }
}

const query = graphql`
  query {
    topBlob: contentfulAsset(title: { eq: "Blob7" }) {
      fixed(height: 684) {
        srcWebp
      }
    }
    bottomBlob: contentfulAsset(title: { eq: "Blob6" }) {
      fixed(height: 193) {
        srcWebp
      }
    }
  }
`

const ConceptPage: FC<Props> = ({ pageContext: { concept } }) => {
  const { topBlob, bottomBlob } = useStaticQuery(query)

  const { client } = concept
  const gallerySources = concept.gallery.map(({ fixed }) => parseContentfulFileUrl(fixed.srcWebp))
  const thumbnails = concept.thumbnails.map(({ fixed }) => parseContentfulFileUrl(fixed.srcWebp))

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{client.name}</title>
        <meta name="description" content={client.slogan} />
        <html lang="en" />
      </Helmet>
      <main style={{ position: 'relative' }}>
        <Header />
        <Spacer size="header" />
        <Spacer size="large" />
        <Visible lg xl xxl>
          <div style={{ position: 'absolute', top: 0, left: 0, zIndex: -10 }}>
            <img src={parseContentfulFileUrl(topBlob.fixed.srcWebp)} alt="" />
          </div>
        </Visible>
        <Container>
          <Row>
            <Col md={8} offset={{ md: 2 }}>
              <Fade direction="up" triggerOnce>
                <div>
                  <Title2>{client.name}</Title2>
                  <Spacer h24 />
                  <Body1>{concept.introduction?.introduction}</Body1>
                  <Spacer h20 />
                  <PillList pills={client.tags} />
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
        <Spacer size="large" />
        {gallerySources[0] && (
          <>
            <Reveal triggerOnce keyframes={animations.slideUp}>
              <BannerImageContainer>
                <Image
                  rounded={false}
                  src={gallerySources[0]}
                  preview={thumbnails[0]}
                  alt={client.name}
                />
              </BannerImageContainer>
            </Reveal>
            <Spacer size="large" />
          </>
        )}
        <Container>
          <Row>
            <Col md={8} offset={{ md: 2 }}>
              <Reveal triggerOnce keyframes={animations.slideUpFadeIn}>
                <span>
                  <Headline1>The brief</Headline1>
                  <Spacer h08 />
                  <Body1>{concept.brief?.brief}</Body1>
                </span>
              </Reveal>
            </Col>
          </Row>
          {gallerySources[1] && (
            <>
              <Spacer size="large" />
              <Row>
                <Col>
                  <Reveal triggerOnce keyframes={animations.zoomFade}>
                    <FullWidthImage
                      src={gallerySources[1]}
                      preview={thumbnails[1]}
                      alt={client.name}
                    />
                  </Reveal>
                </Col>
              </Row>
            </>
          )}
          <Spacer size="large" />
          {concept.development && (
            <>
              <Row>
                <Col md={8} offset={{ md: 2 }}>
                  <Reveal triggerOnce keyframes={animations.slideUpFadeIn}>
                    <span>
                      <Headline1>Developing the concept</Headline1>
                      <Spacer h08 />
                      <Body1>{concept.development.development}</Body1>
                    </span>
                  </Reveal>
                </Col>
              </Row>
              <Spacer size="large" />
            </>
          )}
          {concept.quotes?.[0] && (
            <>
              <Reveal triggerOnce keyframes={animations.slideUp}>
                <CenteredContent>
                  <Testimonial quote={concept.quotes[0]} />
                </CenteredContent>
              </Reveal>
              <Spacer size="large" />
            </>
          )}
          {gallerySources[2] && (
            <>
              <Row>
                <Col>
                  <Reveal triggerOnce keyframes={animations.zoomFade}>
                    <FullWidthImage
                      src={gallerySources[2]}
                      preview={thumbnails[2]}
                      alt={client.name}
                    />
                  </Reveal>
                </Col>
              </Row>
              <Spacer size="large" />
            </>
          )}
          <Row>
            <Col md={8} offset={{ md: 2 }}>
              <Reveal triggerOnce keyframes={animations.slideUpFadeIn}>
                <span>
                  <Headline1>Our solution</Headline1>
                  <Spacer h08 />
                  <Body1>{concept.solution?.solution}</Body1>
                </span>
              </Reveal>
            </Col>
          </Row>
          {gallerySources[3] && (
            <>
              <Spacer size="large" />
              <Row>
                <Col>
                  <Reveal triggerOnce keyframes={animations.zoomFade}>
                    <FullWidthImage
                      src={gallerySources[3]}
                      preview={thumbnails[3]}
                      alt={client.name}
                    />
                  </Reveal>
                </Col>
              </Row>
            </>
          )}
          {concept.quotes?.[1] && (
            <>
              <Spacer size="large" />
              <Reveal triggerOnce keyframes={animations.slideUp}>
                <CenteredContent>
                  <Testimonial quote={concept.quotes[1]} />
                </CenteredContent>
              </Reveal>
            </>
          )}
          {gallerySources[4] && (
            <>
              <Spacer size="large" />
              <Row>
                <Col>
                  <Reveal triggerOnce keyframes={animations.zoomFade}>
                    <FullWidthImage
                      src={gallerySources[4]}
                      preview={thumbnails[4]}
                      alt={client.name}
                    />
                  </Reveal>
                </Col>
              </Row>
            </>
          )}
          <Spacer size="large" />
          <Row>
            <Col md={8} offset={{ md: 2 }}>
              <Reveal triggerOnce keyframes={animations.slideUpFadeIn}>
                <span>
                  <Headline1>The outcome</Headline1>
                  <Spacer h08 />
                  <Body1>{concept.outcome?.outcome}</Body1>
                </span>
              </Reveal>
            </Col>
          </Row>
          <Spacer size="large" />
          <Row>
            <Col md={8} offset={{ md: 2 }}>
              <Reveal triggerOnce keyframes={animations.zoomFade}>
                <SocialLinks
                  mode="dark"
                  twitter={concept.twitter}
                  linkedin={concept.linkedin}
                  facebook={concept.facebook}
                  instagram={concept.instagram}
                />
              </Reveal>
            </Col>
          </Row>
        </Container>
        <Spacer size="large" />
        <div style={{ position: 'relative' }}>
          <DoYouHaveAnIdea />
          <Spacer size="large" />
          <Visible lg xl xxl>
            <div style={{ position: 'absolute', top: 0, right: 0, zIndex: -10 }}>
              <img src={parseContentfulFileUrl(bottomBlob.fixed.srcWebp)} alt="" />
            </div>
          </Visible>
        </div>
        <Footer />
      </main>
    </>
  )
}

export default ConceptPage
