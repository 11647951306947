import React, { FC } from 'react'
import QuotationMark from '../../../assets/svg/QuotationMark'
import { Spacer } from '../../../theme/base'
import { Quote } from '../../../types'
import * as S from './styled'

type Props = {
  quote: Quote
}

const Testimonial: FC<Props> = ({ quote: { image, name, quote, title } }) => {
  const src = image.fixed.srcWebp

  return (
    <S.Container>
      <S.ImageWrapper>
        <S.ProfilePic src={src} rounded={false} />
        <S.QuoteBadgeWrapper>
          <QuotationMark />
        </S.QuoteBadgeWrapper>
      </S.ImageWrapper>
      <S.TextContainer>
        <S.Quote>{quote.quote}</S.Quote>
        <Spacer h36 />
        <S.Author>{name}</S.Author>
        <Spacer h12 />
        <S.JobTitle>{title}</S.JobTitle>
      </S.TextContainer>
    </S.Container>
  )
}

export default Testimonial
