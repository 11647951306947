import React, { FC } from 'react'
import { Zoom } from 'react-awesome-reveal'
import { Col, Container, Row } from 'react-grid-system'
import ChatBubble from '../../assets/svg/ChatBubble'
import { Spacer } from '../../theme/base'
import { Title2 } from '../../theme/typography'
import Button from '../Button'
import CustomLink from '../__general/CustomLink/CustomLink'

type Props = {}

const DoYouHaveAnIdea: FC<Props> = () => {
  return (
    <Zoom triggerOnce>
      <Container>
        <Row>
          <Col xl={8} offset={{ xl: 2 }} className="d-flex fd-col justify-content-center">
            <Title2 centered>Do you need our expertise? Let's talk.</Title2>
            <Spacer exact={64} />
            <CustomLink to="/contact" style={{ margin: '0 auto' }}>
              <Button Icon={ChatBubble} title="Let's talk" />
            </CustomLink>
          </Col>
        </Row>
      </Container>
    </Zoom>
  )
}

export default DoYouHaveAnIdea
